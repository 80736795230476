import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 30 min. `}<meta itemProp="prepTime" content="PT30M" /></h5>
    <h5>{`Cook time: 45 min. `}<meta itemProp="cookTime" content="PT45M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`2-4`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Seafood, 10-14 oz.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Mayonnaise (or yoghurt), 1 heaping tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Stone ground mustard, 1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Capers, 1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Lemon, juice from half lemon</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Smoked paprika, 1/4 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Parmesan, 1/4 cup shredded</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt and pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Eggs, 2</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Bread crumbs, 1/4 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Olive oil, for greasing pan</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Grater</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Lemon squeezer</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Grate parmesan</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Mix all ingredients in a medium bowl.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Shape into 4 patties.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Chill for at least 10 minutes and freeze, if desired. Thaw overnight before frying.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Oil skillet and fry until golden brown, ~2-4 minutes per side.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      